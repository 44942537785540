import React, { useEffect, useState, useRef, useMemo } from 'react';
import { IScreen, TDesign, TOnlineStore } from 'type';
import { CMSLayout, Col, Row, Text, ShimmerLoading, useUIState, Grid, modal, showPopupMessage, ImageGallery, BgIcon, InputField, SelectField, TextAreaField, ButtonOutline } from 'components';
import { useNavFunc } from 'navigation';
import { COLORS, SCREEN } from 'const';
import Store from 'store';
import { Pressable, ScrollView, useWindowDimensions } from 'react-native';
import Variants from './ResellerCreateMyOwnProduct.Variants';
import PublishProductModal from './PublishProductModal';
import { notification, Button as AntButton } from 'antd';
import { ValHelper, modalConfirm } from 'helpers';
import { useNavigationMiddleWare } from 'navigation/NavigationContext';
import { Tooltip } from 'react-tippy';
import OrderSampleModal from './OrderSampleModal';
import CandleStickerModal from './CandleStickerModal';
import EditDesignModal from './ResellerCreateMyOwnProduct.EditDesignModal';
import BuyNowModal from './BuyNowModal';

const RequireSaved = ({ children, design }) => {
  const ButtonWrapper = design?.id ? Col : Tooltip;
  return (
    <ButtonWrapper
      title="You need to save product before publishing to store"
      trigger="mouseenter"
      position="top"
    >
      {children}
    </ButtonWrapper>
  )
}
const screenName = "ResellerCreateMyOwnProduct";
const ResellerCreateMyOwnProduct: IScreen = () => {
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const ProductStore = Store.useProductStore();
  const ShopStore = Store.useShopStore();
  const DesignStore = Store.useDesignStore();
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { productId, designId } = route.params || {};
  const sampleModelRef = useRef<any>(null);
  const candleModalRef = useRef<any>(null);
  const buyNowModalRef = useRef<any>(null);
  const { product, setProduct, uiState, hasDoneSomeEditing } = ProductStore.useProduct(productId);
  const [sampleDesignData, setSampleDesignData] = useState({
    designId: '',
    image: '',
    galleries: [],
    style: '',
  });
  const [listBrandOption, setListBrandOption] = useState<Array<{ label: string, value: string, data: TOnlineStore }>>([]);
  const { design, setDesign, uiState: uiStateDesign } = DesignStore.useDesign(designId, false, false);
  const [galleries, setGalleries] = useState({
    mainImage: '',
    images: [],
  });
  const firstTimeSetGallery = useRef(false);
  useEffect(() => {
    firstTimeSetGallery.current = false;
  }, [designId]);
  useEffect(() => {
    /*
      <ImageGallery
        mainImage={design?.image}
        images={design?.galleries || []}
      />
    */
    if (!!design && !firstTimeSetGallery.current) {
      setGalleries({
        mainImage: design.image,
        images: design.galleries || [],
      });
      firstTimeSetGallery.current = true;
    }
  }, [design]);

  const { updateEditting } = useNavigationMiddleWare();
  const [{ loading: saving }, setSavingUI] = useUIState();
  const { height } = useWindowDimensions();
  const [containerHeight, setContainerHeight] = useState(0);
  const alreadyHasVariants = (design?.variants || []).length > 0;

  const productRef = useRef(product);
  useEffect(() => {
    productRef.current = product;
  }, [product])

  const updateDesign = (obj) => {
    setDesign((d: any) => {
      return {
        ...d,
        ...obj,
      }
    });
    updateEditting(screenName, true);
  }

  const setDesignData = (obj) => {
    setDesign((d: any) => {
      return {
        ...d,
        data: {
          ...d?.data,
          ...obj,
        }
      }
    });
    updateEditting(screenName, true);
  }

  const shouldUpdateShopify = () => new Promise<boolean>((resolve) => {
    modalConfirm({
      title: 'Update',
      content: 'Do you want to update the product and variants on Shopify store?',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
      cancelText: "No",
    })
  })

  const getData = async () => {
    try {
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      const list = await ShopStore.getAll(resellerId);
      setListBrandOption(list.map((x) => ({ label: x.name, value: x.id, data: x })));
    } catch (err) { }
  };

  useEffect(() => {
    if (product && designId === "new") {
      const d = {
        name: product.name,
        isCustomizable: true,
        productId: product.id,
        width: product.physicalWidth,
        height: product.physicalHeight,
        printAreas: product.printAreas,
        customProduct: !!product.customProduct,
        printOnDemand: !!product.printOnDemand,
        wholeSale: !!product.wholeSale,
        image: product.image,
        resalePrice: 0,
        description: product.description,
      }
      setDesign(d);
      setTimeout(() => {
        onSubmit(d as TDesign);
      }, 500);
    }
  }, [product]);

  useEffect(() => {
    getData();
  }, []);

  const isCancdleSceneInfoAdded = useRef(false);
  const didAutoFillUserInfoToCandle = useRef(false);

  const isCandle = useMemo(() => {
    return typeof product?.tags?.includes === 'function' && product?.tags.includes('candle');
  }, [product]);

  useEffect(() => {
    if (!design) return;
    if (!isCandle) return;

    isCancdleSceneInfoAdded.current =
      !!(design?.data?.candle?.defaultScentName || design?.data?.candle?.scentName)
      && !!(design?.data?.candle?.companyName)
      && !!(design?.data?.candle?.address);
    // console.log('isCancdleSceneInfoAdded.current', isCancdleSceneInfoAdded.current);
    // console.log('design', design);

    if (didAutoFillUserInfoToCandle.current) return;
    const newDesign = { ...design };
    if (!!user && !design.data?.candle?.companyName) {
      console.log('user?.accountName', user?.accountName);
      console.log('user', user);
      newDesign.data = {
        ...design?.data,
        candle: {
          ...design?.data?.candle,
          companyName: user?.accountName,
        }
      }
    }
    if (!!user && !design.data?.candle?.address) {
      newDesign.data = {
        ...design.data,
        candle: {
          ...newDesign.data?.candle,
          address: [user?.addressLine1, user?.addressLine2, user?.postCode].filter(Boolean).join('\n'),
        }
      }
    }
    setDesign(newDesign);
    didAutoFillUserInfoToCandle.current = true;
  }, [design, isCandle, user]);

  const onSaveCandleSticker = async ({ candle }) => {
    setDesign((d: any) => {
      return {
        ...d,
        data: {
          ...d?.data,
          candle,
        }
      }
    });
    if (designId && designId !== "new") {
      try {
        await Store.Api.Design.upsert({
          id: designId,
          data: {
            ...design.data,
            candle,
          },
          updateShopify: false,
        });
      } catch (err) {
        showPopupMessage({
          title: '',
          content: String(err),
          buttonOkText: 'OK',
          typeHighlight: 'danger',
          contentHighlight: 'Error'
        });
      }
    } else {
      updateEditting(screenName, true);
    }
  }

  const onSubmit = async (design: TDesign | undefined) => {
    let hasError = false;
    // validate variants, reject if any variant has no style or price
    // if ((design?.variants || []).some(v => !v.style || !v.price)) {
    //   showPopupMessage({
    //     title: '',
    //     content: 'Please fill in all variant style and price.',
    //     buttonOkText: 'OK',

    //     typeHighlight: 'danger',
    //     contentHighlight: 'Error'

    //   });
    //   // alert('Please fill in all variant style and price');
    //   hasError = true;
    //   return hasError;
    // }
    setSavingUI({ loading: true });
    try {
      let updateShopify = false;
      if (design?.products?.length) {
        updateShopify = await shouldUpdateShopify();
      }
      let res;
      if (!designId || designId === "new") {
        res = await Store.Api.Design.upsert({
          name: design.name,
          isCustomizable: true,
          productId: product.id,
          width: product.physicalWidth,
          height: product.physicalHeight,
          printAreas: product.printAreas,
          customProduct: !!product.customProduct,
          printOnDemand: !!product.printOnDemand,
          wholeSale: !!product.wholeSale,
          image: product.image,
          description: design.description,
          resalePrice: +design.resalePrice,
          data: design.data || {},
          brands: design.brands,
          updateShopify,
        });
      } else {
        res = await Store.Api.Design.upsert({
          id: designId,
          name: design.name,
          resalePrice: +design.resalePrice,
          data: design.data,
          brands: design.brands,
          description: design.description,
          updateShopify,
          variants: design.variants.map(v => ({
            ...v,
            price: +v.price,
          }))
        });
      }

      updateEditting(screenName, false);
      if (res.data.success) {
        if (designId === "new") {
          navigation.reset({
            index: 0,
            routes: [{ name: SCREEN.ResellerCreateMyOwnProduct, params: { designId: res.data.data.id, productId: product.id } }],
          });
        }
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',

        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(err);
      hasError = true;
    }
    setSavingUI({ loading: false });
    return hasError;
  }

  const onPressBuyNow = async () => {
    if (isCandle && !isCancdleSceneInfoAdded.current) {
      modalConfirm({
        title: 'Warning',
        content: 'Please update Candle label information',
        onOk: () => candleModalRef.current.show(design?.data?.candle),
        onCancel: () => { },
      });
      return;
    }
    setSampleDesignData({
      designId: design.id,
      image: design.image,
      galleries: design.galleries,
      style: 'Default Style',
    });
    buyNowModalRef.current.show(design);
  }

  const showPublishProductModal = async () => {
    if (isCandle && !isCancdleSceneInfoAdded.current) {
      modalConfirm({
        title: 'Warning',
        content: 'Please update Candle label information',
        onOk: () => { },
        onCancel: () => { },
      });
      return;
    }
    const missingResalePrice = !design.resalePrice || design.resalePrice == 0;
    const missingVariantPrice = design.variants?.some(i => !i.price || i?.price == 0);

    if (missingResalePrice && !alreadyHasVariants) {
      modalConfirm({
        title: 'Warning',
        content: 'Please fill in Resale price',
        onOk: () => { },
        onCancel: () => { },
      });
      return;
    }
    if (alreadyHasVariants && missingVariantPrice) {
      modalConfirm({
        title: 'Warning',
        content: 'Please fill in price of all variants',
        onOk: () => { },
        onCancel: () => { },
      });
      return;
    }
    const hasError = await onSubmit(design);
    if (hasError) return;
    modal.show(
      <PublishProductModal
        design={design}
        setDesign={setDesign}
        cost={product?.price}
        beforePublish={async () => {
          await Store.Api.Design.upsert({
            id: designId,
            name: design.name,
            resalePrice: +design.resalePrice,
            data: design.data,
            brands: design.brands,
            description: design.description,
          });
        }}
        navigation={navigation}
      />
    );
  }

  const showEditDesignModal = async () => {
    const hasError = await onSubmit(design);
    if (hasError) return;
    // navigation.navigate(SCREEN.ResellerEditDesign, {
    //   productId: design.productId,
    //   designId: design.id,
    // });
    modal.show(
      <EditDesignModal designId={designId} onDesignSave={async (id) => {
        console.log('running onDesignSave', id);
        if (id === designId) {
          const res = await Store.Api.Design.detail({ id });
          if (res.data.success) {
            setDesign(res.data.data);
            setGalleries({
              mainImage: res.data.data?.image,
              images: res.data.data?.galleries || [],
            })
          }
        }
      }} />
    );
  }

  const handleOrderSample = () => {
    if (isCandle && !isCancdleSceneInfoAdded.current) {
      modalConfirm({
        title: 'Warning',
        content: 'Please update Candle label information',
        onOk: () => candleModalRef.current.show(design?.data?.candle),
        onCancel: () => { },
      });
      return;
    }
    setSampleDesignData({
      designId: design.id,
      image: design.image,
      galleries: design.galleries,
      style: 'Default Style',
    });
    sampleModelRef.current.show();
  };

  const onPressOrderSample = () => {
    if ((design?.galleries || []).length === 0) {
      showPopupMessage({
        title: '',
        content: 'Please click Edit design first',
        buttonOkText: 'OK',
        typeHighlight: 'warning',
        contentHighlight: 'No design detected',
      });
    } else {
      handleOrderSample();
    }
  }

  const mainContent = () => {
    return (
      <>
        <ScrollView
          style={{ height: containerHeight - 56 }}
        >
          <Grid xs='100%' md='100%' lg='50%' marginHorizontal={8} marginTop={16} alignItems="flex-start" flex1>
            <Col marginHorizontal={8} marginTop={8}>
              <ImageGallery
                mainImage={galleries.mainImage}
                images={galleries.images || []}
              />
            </Col>
            <Col marginHorizontal={8} marginTop={8}>
              <InputField
                title="Product name"
                icon="edit"
                value={design?.name}
                onChangeText={(v) => {
                  updateDesign({ name: v })
                }}
                marginBottom={16}
              />
              <InputField
                title="Resale price"
                icon="edit"
                value={String(design?.resalePrice || "")}
                onChangeText={(v) => {
                  updateDesign({ resalePrice: v.replace(/ /g, "").trim() })
                }}
                inputProps={{
                  prefix: (
                    <Text color={COLORS.TEXT_MUTED}>£</Text>
                  )
                }}
              />
              {!!design?.resalePrice && !isNaN(design?.resalePrice) && !!product?.price && (
                <Text marginTop={8} fontSize={12} color={COLORS.ICON}>
                  Profit: £{design?.resalePrice} (sell price) - £{product?.price} = £{(+design?.resalePrice - product?.price).toFixed(2)}
                </Text>
              )}
              <SelectField
                title="Which stores do you want to connect this products to"
                marginTop={16}
                marginBottom={16}
                isMultiple
                value={(design?.brands || []).map(i => i.storeId)}
                options={listBrandOption}
                onChange={(newValues: any[], opts: any) => {
                  updateDesign({
                    brands: opts.map(v => ({ storeId: v.value, name: v.label }))
                  })
                }}
                right={(
                  <RequireSaved design={design}>
                    <AntButton
                      type="text" size="large"
                      disabled={!design?.id}
                      onClick={showPublishProductModal}
                    >
                      <Text fontSize={16} fontWeight="600" color={COLORS.BLUE_LIGHT}>
                        {design?.products?.length ? 'Store products' : 'Publish'}
                      </Text>
                    </AntButton>
                  </RequireSaved>
                )}
              />
              <TextAreaField
                title="Description"
                icon="edit"
                value={design?.description || ''}
                onChangeText={(v) => updateDesign({ description: v })}
              />
              <Col height={1} backgroundColor={COLORS.BORDER} marginVertical={16} />
              <Text marginBottom={16} fontSize={16} fontWeight="600">General information</Text>

              <Row marginBottom={16}>
                <Col flex1>
                  {/* <InputField
                    title="Product price (exclude VAT)"
                    value={String(product?.price)}
                    inputProps={{
                      disabled: true,
                      suffix: (
                        <Text color={COLORS.TEXT_MUTED}>£</Text>
                      )
                    }}
                  /> */}
                  <Text marginBottom={8} fontWeight="500" fontSize={14}>Product price</Text>
                  <Col height={44} justifyContent="center">
                    <Text fontSize={16} fontWeight="500">£{ValHelper.formatMoney(product?.price)} (£{ValHelper.formatMoney(product?.price * 1.2)} inc VAT)</Text>
                  </Col>
                </Col>
              </Row>
              {!alreadyHasVariants && (
                <RequireSaved design={design}>
                  <AntButton
                    size="large"
                    type="text"
                    onClick={showEditDesignModal}
                    style={{ backgroundColor: COLORS.BORDER }}
                  >
                    <Text fontSize={16} fontWeight="600">Edit design</Text>
                  </AntButton>
                </RequireSaved>
              )}

              <Row marginTop={16}>
                <Col flex1>
                  <ButtonOutline
                    icon="media"
                    title="View artboard"
                    onPress={() => {
                      window.open(product?.artboardUrl || "https://www.bottledgoose.co.uk/pages/artboards", "_blank");
                    }}
                  />
                </Col>
                <Col width={16} />
                <Col flex1>
                  <ButtonOutline
                    icon="book"
                    title="Artwork guidelines"
                    onPress={() => {
                      window.open("https://print-manager-media.s3.eu-west-1.amazonaws.com/bg/Artwork-Guide-Customiser.pdf", "_blank");
                    }}
                  />
                </Col>
              </Row>
              <Row marginTop={16}>
                <Col flex1>
                  <ButtonOutline
                    icon="document"
                    title="Order a sample"
                    onPress={onPressOrderSample}
                  />
                </Col>
                <Col width={16} />
                <Col flex1>
                  {isCandle && (
                    <ButtonOutline
                      icon="search-template"
                      title="Candle sticker info"
                      onPress={() => candleModalRef.current.show(design?.data?.candle)}
                    />
                  )}
                </Col>
              </Row>
              {!!design?.galleries?.length && (
                <Variants
                  design={design}
                  setDesign={setDesign}
                  onFocusVariant={(variant) => {
                    setGalleries({
                      mainImage: variant.image,
                      images: variant.galleries || [],
                    })
                  }}
                  onRequestSampleOrder={variant => {
                    if (isCandle && !isCancdleSceneInfoAdded.current) {
                      modalConfirm({
                        title: 'Warning',
                        content: 'Please update Candle label information',
                        onOk: () => { },
                        onCancel: () => { },
                      });
                      return;
                    }
                    setSampleDesignData({
                      designId: variant.variantDesignId,
                      image: variant.image,
                      galleries: variant.galleries,
                      style: variant.style,
                    });
                    sampleModelRef.current.show();
                  }}
                  product={product}
                />
              )}
            </Col>
          </Grid>
          <Col height={84} />
        </ScrollView >
      </>
    )
  }

  return (
    <CMSLayout requireAuthen
      breadcrumbs={[
        { title: `My Products`, screen: SCREEN.ListDessignsReseller },
        { title: design?.name || `Product details` },
      ]}
    >
      {uiState.errorMes ? (
        <Col flex1 middle>
          <Text color="red" subtitle1>{uiState.errorMes}</Text>
        </Col>
      ) : (
        uiState.fetching ? (
          <Row height={50} stretch>
            <ShimmerLoading round1 flex={1} m1 />
            <ShimmerLoading round1 flex={1} m1 />
            <ShimmerLoading round1 flex={1} m1 />
            <ShimmerLoading round1 flex={1} m1 />
          </Row>
        ) : (
          <Col borderWidth={1} borderColor={COLORS.BORDER} bgWhite
            flex1
            style={{
              overflow: 'hidden',
            }}
            onLayout={(e) => {
              const height = e.nativeEvent.layout.height;
              setContainerHeight(height);
            }}
          >
            {mainContent()}
            <Col absolute bottom={0} left={0} right={0}>
              <Row
                borderTopColor={COLORS.BORDER} bgWhite
                borderTopWidth={1} paddingHorizontal={16} paddingVertical={12}
                justifyContent="flex-end"
              >
                <AntButton
                  size="large"
                  loading={saving}
                  type="primary"
                  onClick={onPressBuyNow}
                  style={{ marginLeft: 16, backgroundColor: COLORS.BLUE }}
                >
                  <Text fontSize={16} fontWeight="600" colorWhite>Buy now</Text>
                </AntButton>
                <AntButton
                  size="large"
                  loading={saving}
                  type="primary"
                  onClick={() => onSubmit(design)}
                  style={{ marginLeft: 16, backgroundColor: COLORS.GREEN }}
                >
                  <Text fontSize={16} fontWeight="600" colorWhite>Save changes</Text>
                </AntButton>
              </Row>
            </Col>
          </Col>
        )
      )}
      <OrderSampleModal
        ref={sampleModelRef}
        productId={design?.productId}
        productName={product?.name}
        sampleDesignData={sampleDesignData}
      />
      <CandleStickerModal
        ref={candleModalRef}
        product={product}
        setDesignData={onSaveCandleSticker}
      />
      <BuyNowModal
        ref={buyNowModalRef}
        product={product}
        sampleDesignData={sampleDesignData}
      />
    </CMSLayout>
  );
};

ResellerCreateMyOwnProduct.routeInfo = {
  title: 'Create my own - Bottled Goose',
  path: '/product-library/:productId/create-my-own/:designId',
};

export default ResellerCreateMyOwnProduct;
