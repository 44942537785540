import { Col, Row, Text, CMSLayout, useUIState, TouchField, Button, Grid, modal, Table, showPopupMessage, Input, BgIcon } from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { IScreen, TCMSOrder } from 'type';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { Image } from 'react-native';
import moment from 'moment';
import { FontAwesome5, MaterialCommunityIcons, Ionicons } from '@expo/vector-icons';
import { ValHelper } from 'helpers';
import { commonFuncs } from './ListOrders.funcs';
import { ListOrderLoading } from './ListOrders.Comps';
import DispatchRoyal from './DispatchRoyal';
import apiClient from 'store/api-client';
import { Descriptions, DescriptionsProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Tooltip } from 'react-tippy';
import OverrideModal from './OrderDetail.OverrideModal';

const shippingServices = {
  RM24: "Royal Mail 24",
  RM48: "Royal Mail 48",
  DPD: "DPD",
  RMInternational: 'Royal Mail International',
};

const shippingPrice = {
  RM24: 5.2,
  // RM48: 4.18,
  RM48: 4.2,
  DPD: 10.99,
};
const EUROPEAN_COUNTRIES = ["Russia", "Germany", "France", "Italy", "Spain", "Poland", "Ukraine", "Romania", "Netherlands", "Belgium", "Sweden", "Czech Republic (Czechia)", "Greece", "Portugal", "Hungary", "Belarus", "Austria", "Switzerland", "Serbia", "Bulgaria", "Denmark", "Slovakia", "Finland", "Norway", "Ireland", "Croatia", "Moldova", "Bosnia and Herzegovina", "Albania", "Lithuania", "Slovenia", "North Macedonia", "Latvia", "Estonia", "Luxembourg", "Montenegro", "Malta", "Iceland", "Andorra", "Liechtenstein", "Monaco", "San Marino", "Holy See"];

const shippingPriceEurope = {
  RM24: 8.99,
  RM48: 15,
  RMInternational: 15,
  DPD: 14.99,
};
const shippingPriceTheRestOfTheWorld = {
  RM24: 8.99,
  RM48: 22,
  RMInternational: 22,
  DPD: 14.99,
};

const OrderDetail: IScreen = ({ }) => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { orderId } = route.params || {};

  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const resellerId = user?.role === 'admin' ? 'all' : user?.role === 'reseller' ? user?.id
    : user?.resellerId;

  const OrderStore = Store.useOrderStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: true });
  const [order, setOrder] = useState<TCMSOrder>();
  const [previewData, setPreviewData] = useState<any>({});
  const [trackingNumber, setTrackingNumber] = useState('');
  const [customShippingMethod, setCustomShippingMethod] = useState('');
  const [shippedTime, setShippedTime] = useState('');
  const [invoiceData, setInvoiceData] = useState<any>({});


  const getData = async () => {
    setUI({ fetching: true });
    try {
      const res = await OrderStore.findByOrderNo('', resellerId, orderId);
      const findClient = OrderStore.clients.find(v => v['Client ID'] === res[0]["Client ID"]);
      const fetchPipelines = await OrderStore.getPipelinesByOrderId(findClient?.slug || "bg", orderId);
      if (res[0]) {
        let invoice = await apiClient.Api.Payment.getOrderInvoice({ orderId });
        if (!invoice.data?.data) {
          await OrderStore.getChargeAmount([{
            ...res[0],
            Pipelines: fetchPipelines,
          }])
          invoice = await apiClient.Api.Payment.getOrderInvoice({ orderId });
        }
        setInvoiceData(invoice.data?.data);
      }
      setOrder({
        ...res[0],
        Pipelines: fetchPipelines,
      });
    } catch (err) {
    }
    setUI({ fetching: false });
  }

  useEffect(() => {
    if (!resellerId) return;
    getData();
  }, [orderId, resellerId]);

  useEffect(() => {
    let didSetTrackingNumber = false
    const last = order?.Pipelines[order?.Pipelines.length - 1];
    if (last && last.SharedData && last.SharedData.trackingNumber) {
      setTrackingNumber(last.SharedData.trackingNumber);
      didSetTrackingNumber = true;
    }
    if (last && last.SharedData && last.SharedData.shippingMethod) {
      setCustomShippingMethod(last.SharedData.shippingMethod);
    }
    if (last?.SharedData?.shippedOn) {
      setShippedTime(
        moment(last?.SharedData?.shippedOn).format('DD/MM/YYYY HH:mm')
      )
    }
    if (!didSetTrackingNumber && !!last?.SharedData?.trackingNumber) {
      setTrackingNumber(last.SharedData.trackingNumber)
    }
  }, [order]);

  const canBeProcessedItem = useMemo(() => {
    const pipelines = order?.Pipelines;
    if (!pipelines || pipelines.length === 0) return [];
    return pipelines[pipelines.length - 1].SharedData?.canBeProcessedItems || [];
  }, [order]);

  useEffect(() => {
    if (canBeProcessedItem.length === 0) return;
    const thumbMatch = {};
    canBeProcessedItem.forEach(v => {
      if (v.previewUrl) {
        thumbMatch[v.id] = v.previewUrl;
      }
    });
    setPreviewData(thumbMatch);
    // Promise.all(canBeProcessedItem.map(async (item) => {
    //   try {
    //     if (!item.printJobId) return;
    //     const res = await Store.Api.PrintJob.detail({ id: item.printJobId });
    //     const printJob : TPrintJob = res.data;
    //     if (!!printJob && !!printJob.previewUrl) {
    //       thumbMatch[item.id] = printJob.previewUrl;
    //     }
    //   } catch(err) {}
    // })).then(() => {
    //   setPreviewData(thumbMatch);
    // })
  }, [canBeProcessedItem]);

  const orderStatusURL = useMemo(() => {
    try {
      return new URL(order?.['Raw Data']?.order_status_url)?.hostname
    } catch (error) {
      return '';
    }
  }, [order]);

  const showDispatchModal = () => {
    modal.show(
      <DispatchRoyal
        order={order}
      />
    )
  }

  const renderAddress = (data: any) => {
    if (!data) return '';
    return [data.address1, data.address2, data.city, data.country].filter(Boolean).join(', ')
  }

  const refreshData = () => {
    navigation.reset({
      index: 0,
      routes: [
        { name: SCREEN.OrderDetail, params: { orderId: order['Order ID'] } },
      ],
    });
  }

  const markAsAccepted = async () => {
    if (user?.role === 'admin') {
      await OrderStore.updateOrderStage([order], {
        Status: "Accepted",
        Stage: "In Production",
        StageStatus: "On Time",
      });
      refreshData();
    } else {
      commonFuncs.resellerPayPendingOrder([order], navigation, refreshData);
    }
  }

  const goBack = () => {
    // check if can go back
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({
      index: 0,
      routes: [{ name: SCREEN.ListOrders }],
    });
  }

  const unPaidAmount = useMemo(() => {
    const shippingMethod = invoiceData?.shippingAddress?.country !== 'United Kingdom'
      ? 'Royal Mail International' : 'RM48';

    const postage = (() => {
      const address = invoiceData?.shippingAddress;
      if (address?.country === 'United Kingdom') {
        return shippingPrice.RM48 || 0;
      }
      // TODO: handle international shipping fee
      if (EUROPEAN_COUNTRIES.includes(address?.country)) {
        return shippingPriceEurope.RM48 || 0;
      }
      return shippingPriceTheRestOfTheWorld.RM48 || 0;
    })();

    const productPrice = invoiceData?.total ? invoiceData?.total - invoiceData?.taxes : 0;
    console.log('unPaidAmount productPrice', productPrice);

    const vat = invoiceData?.shippingAddress?.country !== 'United Kingdom' ? 0 : (
      productPrice * 0.2 + postage * 0.2
    )

    const total = productPrice + postage + vat;

    return {
      productPrice,
      shippingMethod,
      postage,
      vat,
      total
    }
  }, [invoiceData])

  console.log('unPaidAmount', unPaidAmount);

  const invoiceInfo = useMemo(() => {
    let _productPrice = invoiceData?.total ? invoiceData?.total - invoiceData?.taxes : 0;
    let _shippingFee = invoiceData?.data?.shippingFee;
    let _vat = invoiceData?.taxes ? (invoiceData?.total - invoiceData?.taxes + _shippingFee) * 0.2 : 0;
    let _total = !invoiceData?.paidAt ? unPaidAmount.total : invoiceData?.total + (_shippingFee || 0) * (invoiceData?.taxes ? 1.2 : 1);

    if (invoiceData?.stripeInvoice) {
      const item = invoiceData?.stripeInvoice;

      const shipItem = item.lines?.data?.find(i => i.description?.includes("Shipping fee"));
      const taxItem = item.lines?.data?.find(i => i.description?.includes("VAT "));

      if (!shipItem && !taxItem) {
        _productPrice = item.total / 100;
      } else {
        const productsOnly = item.total - (shipItem?.amount || 0) - (taxItem?.amount || 0);
        _productPrice = productsOnly / 100;
      }
      if (shipItem) {
        _shippingFee = shipItem?.amount / 100;
      }
      if (taxItem) {
        _vat = taxItem?.amount / 100;
      }
      _total = item.total / 100;
    }
    return {
      productPrice: _productPrice,
      shippingFee: _shippingFee,
      vat: _vat,
      total: _total,
    }
  }, [invoiceData, unPaidAmount]);

  

  console.log('invoiceInfo', invoiceInfo);

  const renderJob = () => {
    if (fetching) return (
      <Col flex1>
        <Text>Loading order data..</Text>
      </Col>
    );
    if (!order) {
      return (
        <Col flex1>
          <Text>No order found</Text>
        </Col>
      )
    }
    const renderLineItemPreview = (lineItemId) => {
      if (!previewData[lineItemId]) return null;
      return (
        <Image source={{ uri: previewData[lineItemId] }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
      )
    }
    const isPaid = order.Pipelines[order.Pipelines.length - 1]?.SharedData.isPaid;
    const items: DescriptionsProps['items'] = [
      {
        key: 'stage',
        label: 'Stage',
        children: order.Stage,
        span: 3,
      },
      {
        key: 'status',
        label: 'Status',
        children: order.StageStatus,
        span: 3,
      },
      {
        key: 'orderNo',
        label: 'Order Number',
        children: order['Order Number'],
        span: 3,
      },
      {
        key: 'created',
        label: 'Created',
        children: moment(order.CreatedAt).format('DD/MM/YYYY HH:mm:ss'),
        span: 3,
      },
      {
        key: 'paid',
        label: 'Paid',
        children: isPaid ? moment(order.UpdatedAt).format('DD/MM/YYYY HH:mm:ss') : "",
        span: 3,
      },
      {
        key: 'shipped',
        label: 'Shipped',
        children: shippedTime,
        span: 3,
      },
      {
        key: 'shippingMethod',
        label: 'Shipping Method',
        children: (() => {
          if (customShippingMethod) return customShippingMethod;
          console.log('invoiceData', invoiceData);
          const shippingService = invoiceData?.data?.shippingService;
          if (!shippingService) return unPaidAmount.shippingMethod;
          if (shippingService === 'RM48' && invoiceData?.shippingAddress?.country !== 'United Kingdom') {
            return 'Royal Mail International';
          }
          return shippingService ? shippingServices[shippingService] : "";
        })(),
        span: 3,
      },
      {
        key: 'trackingNumber',
        label: 'Tracking Number',
        children: <a href={'https://www.royalmail.com/track-your-item#/tracking-results/' + trackingNumber} target="_blank">{trackingNumber}</a>,
        span: 3,
      },
      {
        key: 'paymentMethod',
        label: 'Payment Method',
        children: isPaid ? 'Wallet Credit' : (
          (order.Status === 'Accepted' || order.Status === 'Fulfilled') ? 'Free of charge' : ''
        ),
        span: 3,
      },
      {
        key: 'productPrice',
        label: 'Product Price',
        children: invoiceData.manualInvoiceData?.productPrice
          ? `£${ValHelper.formatMoney(invoiceData.manualInvoiceData?.productPrice)}`
          : `£${ValHelper.formatMoney(invoiceInfo.productPrice)}`,
        span: 3,
      },
      {
        key: 'postage',
        label: 'Postage',
        // separated shipping fee and tax
        children: (() => {
          if (invoiceData.manualInvoiceData?.postage)
            return `£${ValHelper.formatMoney(invoiceData.manualInvoiceData?.postage)}`;
          return !invoiceData?.paidAt ? (
            `£${ValHelper.formatMoney(unPaidAmount.postage)}`
          ) : (
            `£${ValHelper.formatMoney(invoiceInfo.shippingFee)}`
          )
        })(),
        span: 3,
      },
      {
        key: 'vat',
        label: (() => {
          if (invoiceData.manualInvoiceData?.vat) return 'VAT 20%';
          if (invoiceData.manualInvoiceData?.vat === 0) return 'VAT not applicable';
          return invoiceData?.taxes ? 'VAT 20%' : 'VAT not applicable';
        })(),
        children: (() => {
          if (invoiceData.manualInvoiceData?.vat || invoiceData.manualInvoiceData?.vat === 0) return `£${ValHelper.formatMoney(invoiceData.manualInvoiceData?.vat)}`;
          return `£${ValHelper.formatMoney(invoiceInfo.vat)}`;
        })(),
        span: 3,
      },
      {
        key: 'total',
        label: 'Total',
        children: (() => {
          if (invoiceData.manualInvoiceData?.total) return `£${ValHelper.formatMoney(invoiceData.manualInvoiceData?.total)}`;
          return `£${ValHelper.formatMoney(invoiceInfo.total)}`;
        })(),
        span: 3,
      },
      {
        key: 'invoice',
        label: 'Invoice',
        children: (!!invoiceData?.paidAt ||isPaid) && (invoiceData?.data?.stripeInvoiceUrl || invoiceData?.data?.invoicePdf) ? (
          <Col>
            {invoiceData.manualInvoicePdf ? (
              <TouchField
                height={36}
                paddingLeft={5}
                justifyContent={'flex-start'}
                alignItems={'center'}
                flexDirection="row"
                onPress={() => {
                  window.open(invoiceData.manualInvoicePdf);
                }}
              >
                {/* <MaterialCommunityIcons name="download" size={20} color={COLOR.FONT} /> */}
                <Ionicons name="open-outline" size={20} color={COLOR.FONT} />
                <Text ml1>BG Invoice</Text>
              </TouchField>
            ) : invoiceData?.data?.invoicePdf && (
              <TouchField
                height={36}
                paddingLeft={5}
                justifyContent={'flex-start'}
                alignItems={'center'}
                flexDirection="row"
                onPress={() => {
                  window.open(invoiceData?.data?.invoicePdf);
                }}
              >
                {/* <MaterialCommunityIcons name="download" size={20} color={COLOR.FONT} /> */}
                <Ionicons name="open-outline" size={20} color={COLOR.FONT} />
                <Text ml1>BG Invoice</Text>
              </TouchField>
            )}
          </Col>
        ) : "",
        span: 3,
      },
    ];
    const customerItems: DescriptionsProps['items'] = [
      {
        key: 'customer',
        label: 'Customer Name',
        children: order['Customer Name'],
        span: 3,
      },
      {
        key: 'shippingAddress',
        label: 'Shipping Address',
        children: renderAddress(order['Raw Data'].shipping_address),
        span: 3,
      },
      {
        key: 'billingAddress',
        label: 'Billing Address',
        children: renderAddress(order['Raw Data'].billing_address),
        span: 3,
      },
    ];
    const stripeInvoiceData: DescriptionsProps['items'] = [
      {
        key: 'invoiceId',
        label: 'Invoice ID',
        children: !invoiceData?.data?.stripeInvoiceID ? null : (
          <TouchField
            height={36}
            paddingLeft={5}
            justifyContent={'flex-start'}
            alignItems={'center'}
            flexDirection="row"
            onPress={() => {
              window.open(`https://dashboard.stripe.com/invoices/${invoiceData?.data?.stripeInvoiceID}`);
            }}
          >
            <Ionicons name="open-outline" size={20} color={COLOR.FONT} />
            <Text ml1>{invoiceData?.data?.stripeInvoiceID}</Text>
          </TouchField>
        ),
        span: 3,
      },
      {
        key: 'customerId',
        label: 'Customer ID',
        children: !invoiceData?.resellerStripeId ? null : (
          <TouchField
            height={36}
            paddingLeft={5}
            justifyContent={'flex-start'}
            alignItems={'center'}
            flexDirection="row"
            onPress={() => {
              window.open(`https://dashboard.stripe.com/customers/${invoiceData?.resellerStripeId}`);
            }}
          >
            <Ionicons name="open-outline" size={20} color={COLOR.FONT} />
            <Text ml1>{invoiceData?.resellerStripeId}</Text>
          </TouchField>
        ),
        span: 3,
      },
      {
        key: 'resellerName',
        label: 'Reseller Name',
        children: !invoiceData?.resellerName ? null : (
          <TouchField
            height={36}
            paddingLeft={5}
            justifyContent={'flex-start'}
            alignItems={'center'}
            flexDirection="row"
            onPress={() => {
              navigation.navigate(SCREEN.UpsertReseller, { id: invoiceData?.resellerId })
            }}
          >
            {/* <MaterialCommunityIcons name="download" size={20} color={COLOR.FONT} /> */}
            <Ionicons name="open-outline" size={20} color={COLOR.FONT} />
            <Text ml1>{invoiceData?.resellerName}</Text>
          </TouchField>
        ),
        span: 3,
      },
    ];

    const lineItemColumns: ColumnsType<any> = [
      {
        title: 'Product',
        key: 'product',
        render: (record) => (
          <Col width={50} height={50}>
            {renderLineItemPreview(record.id)}
          </Col>
        )
      },
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
      },
      // {
      //   title: 'Product SKU',
      //   dataIndex: 'sku',
      //   key: 'sku',
      // },
      {
        title: 'Store',
        key: 'store',
        render: () => orderStatusURL,
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Your sell price',
        key: 'resalePrice',
        render: (record) => {
          const linePrice = record.price;
          return `£${ValHelper.formatMoney(linePrice)}`;
        }
      },
    ]

    return (
      <Col flex1>
        <Grid xs='100%' md='100%' lg='3:7' alignItems="flex-start">
          <Col p>
            <Descriptions
              title="Order Information"
              bordered
              items={items}
              contentStyle={{ fontSize: 15 }}
              labelStyle={{ fontSize: 15 }}
            />
            {user?.role === 'admin' && (
              <TouchField
                width={140}
                flexDirection={'row'} p1 mt2
                onPress={() => {
                  modal.show(<OverrideModal invoice={invoiceData} />)
                }}
              >
                <BgIcon name='edit' />
                <Text ml0>Admin override</Text>
              </TouchField>
            )}
          </Col>
          <Col p1>
            <Descriptions
              title="Customer Information"
              bordered
              items={customerItems}
              contentStyle={{ fontSize: 15 }}
              labelStyle={{ fontSize: 15 }}
            />
            <Col height={24} />
            <Table
              data={order['Raw Data'].line_items.filter(v => {
                if (order['Item Supported']?.includes(String(v.id))) return true;
                return false;
              })}
              columns={lineItemColumns}
              rowKey={record => `${record.id}`}
              minWidth={800}
            />
            <Col height={24} />
            {user?.role === 'admin' && (
              <Descriptions
                title="Stripe Invoice Data (admin only)"
                bordered
                items={stripeInvoiceData}
                contentStyle={{ fontSize: 15 }}
                labelStyle={{ fontSize: 15 }}
              />
            )}
            
          </Col>
        </Grid>
      </Col>
    )
  }

  const sourceId = order?.['Order Source ID'];
  const orderNumber = order?.['Raw Data']?.order_number;

  return (
    <CMSLayout
      requireAuthen
      searchPlaceholder='Search by Order No'
      onSearchInput={(orderNo) => {
        navigation.navigate(SCREEN.OrderSearchResult, { query: orderNo })
      }}
      searchAutoClear
    >
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Row flex={1}>
          <Button
            solid
            height={30} paddingHorizontal={8}
            iconLeft={<FontAwesome5 name="chevron-left" size={12} color="white" />}
            text='Back'
            fitContent
            mr1
            onPress={goBack}
            borderRadius={15}
          />
          <Text h3 >Order tracking {'>'} Order #{sourceId ? orderId : orderNumber} {!!(sourceId || order?.['Order ID'])  ? ' ID: ' + (sourceId || order?.['Order ID']) : ''}</Text>
          {/* <Text h3 >Order tracking {'>'} Order #{order?.['Raw Data']?.order_number} {!!order?.['Order ID'] ? ' ID: ' + order?.['Order ID'] : ''}</Text> */}
        </Row>
        <Row>
          {order?.Status === 'Fulfilled' && (
            <Button
              solid
              height={30} width={200} text='Reprint'
              onPress={async () => {
                await commonFuncs.reprint(order);
                showPopupMessage({
                  title: '',
                  content: 'This order has been moved back to Accepted tab.',
                  buttonOkText: 'OK',
                  // 
                  typeHighlight: 'success',
                  contentHighlight: 'Success'
          
                });
                // alert('This order has been moved back to Accepted tab');
              }}
              borderRadius={15}
            />
          )}
          {order?.Status === "Pending" && (
            <Tooltip
              title={'Select job/s and pay. Add money to your wallet if you have insufficient funds'}
              position="top"
            >
              <Button
                text={UserStore.user?.role === "admin" ? "Mark as Accepted" : "Pay & Proceed"}
                width={140} height={30} borderRadius={15} ml2
                onPress={markAsAccepted}
              />
            </Tooltip>
          )}
        </Row>
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <ListOrderLoading />
          ) : (
            renderJob()
          )
        )}
      </Col>
    </CMSLayout>
  )
};

OrderDetail.routeInfo = {
  title: 'Order detail - Bottled Goose',
  path: '/order/:orderId',
};

export default OrderDetail;
